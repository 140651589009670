import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/loginpage.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/',
      name: 'load-page',
      component: () => import('@/views/loadpage.vue'),
      beforeEnter: guardMyroute
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Main Dashboard',
        breadcrumb: [
          {
            text: 'Main Dashboard',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/dashboard/cms-analytics',
      name: 'cms-analytics',
      component: () => import('@/views/cmsAnalytics.vue'),
      meta: {
        pageTitle: 'CMS Analytics',
        breadcrumb: [
          {
            text: 'CMS Analytics',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/topics',
      name: 'topics',
      component: () => import('@/views/TopicsComponent.vue'),
      meta: {
        pageTitle: 'Topics',
        breadcrumb: [
          {
            text: 'Topics',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/app-iframe/:levelId/:questionId?',
      name: 'app-iframe',
      component: () => import('@/views/iframe/AppIframeComponent.vue'),
      // beforeEnter: guardMyroute
      meta: {
        layout: 'full',
      },
    },

    {
      path: '/classes',
      name: 'Classes',

      component: () => import('@/views/classes.vue'),
      meta: {
        pageTitle: 'Grades',
        breadcrumb: [
          {
            text: 'Grades',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/classes/subjects/:subjectsId',
      name: 'Subjects',

      component: () => import('@/views/subjects.vue'),
      meta: {
        pageTitle: 'Subjects',
        breadcrumb: [
          {
            text: 'Subjects',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/gallery',
      name: 'Gallery',
      component: () => import('@/views/gallery.vue'),
      meta: {
        pageTitle: 'Gallery',
        breadcrumb: [
          {
            text: 'Gallery',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/videos',
      name: 'Videos',
      component: () => import('@/views/videos.vue'),
      meta: {
        pageTitle: 'Videos',
        breadcrumb: [
          {
            text: 'Videos',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/audios',
      name: 'Audios',
      component: () => import('@/views/audios.vue'),
      meta: {
        pageTitle: 'Audios',
        breadcrumb: [
          {
            text: 'Audios',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/old-question-types',
      name: 'old-question-types',
      component: () => import('@/views/questiontype.vue'),
      meta: {
        pageTitle: 'Old Question Types',
        breadcrumb: [
          {
            text: 'Old Question Types',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/new-question-types',
      name: 'new-question-types',
      component: () => import('@/views/questiontype.vue'),
      meta: {
        pageTitle: 'New Question Types',
        breadcrumb: [
          {
            text: 'New Question Types',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/question-themes',
      name: 'question-themes',
      component: () => import('@/views/themes/Themes.vue'),
      meta: {
        pageTitle: 'Question Themes',
        breadcrumb: [
          {
            text: 'Question Themes',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/plugin-management',
      name: 'plugin-management',
      component: () => import('@/views/components/plugins/PluginManagement.vue'),
      meta: {
        pageTitle: 'Plugin Management',
        breadcrumb: [
          {
            text: 'Plugin Management',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/feedback-questions',
      name: 'feedback-questions',
      props: true,
      component: () => import('@/views/feedback-questions.vue'),
      meta: {

        pageTitle: 'Feedbacks',
        breadcrumb: [
          {
            text: 'Feedbacks',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/old-pending-revisions',
      name: 'old-pending-revisions',
      props: true,
      component: () => import('../views/revisions/revision.vue'),
      meta: {

        pageTitle: 'Old Revisions',
        breadcrumb: [
          {
            text: 'Old Revisions',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/new-pending-revisions',
      name: 'new-pending-revisions',
      props: true,
      component: () => import('../views/revisions/revision.vue'),
      meta: {

        pageTitle: 'New Revisions',
        breadcrumb: [
          {
            text: 'New Revisions',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/revision-report/:userId',
      name: 'revision-report',
      props: true,
      component: () => import('../views/revisions/revisionReport.vue'),
      meta: {

        pageTitle: 'Revisions Report',
        breadcrumb: [
          {
            text: 'Revisions Report',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/tts',
      name: 'tts',
      props: true,
      component: () => import('../views/tts/tts.vue'),
      meta: {

        pageTitle: 'tts',
        breadcrumb: [
          {
            text: 'tts',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/cms-users',
      name: 'cms-users',
      component: () => import('@/views/cms-users.vue'),
      meta: {
        pageTitle: 'Cms Users',
        breadcrumb: [
          {
            text: 'Cms Users',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/app-users',
      name: 'app-users',
      component: () => import('@/views/app-users.vue'),
      meta: {
        pageTitle: 'App Users',
        breadcrumb: [
          {
            text: 'App Users',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/order-management',
      name: 'order-management',
      component: () => import('@/views/order-management.vue'),
      meta: {
        pageTitle: 'Order Management',
        breadcrumb: [
          {
            text: 'Order Management',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/leaderboard',
      name: 'leaderboard',
      component: () => import('@/views/leaderboard.vue'),
      meta: {
        pageTitle: 'Leaderboard',
        breadcrumb: [
          {
            text: 'Leaderboard',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/time-graphs',
      name: 'time-graphs',
      component: () => import('@/views/AppUserTimeGraph.vue'),
      beforeEnter: guardMyroute
    },
    {
      path: '/roles',
      name: 'roles',
      component: () => import('@/views/role.vue'),
      meta: {
        pageTitle: 'Roles',
        breadcrumb: [
          {
            text: 'Roles',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/push-notifications',
      name: 'push-notifications',
      component: () => import('@/views/notifications/Notifications.vue'),
      meta: {
        pageTitle: 'Push Notifications',
        breadcrumb: [
          {
            text: 'Push Notifications',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/email-notifications',
      name: 'email-notifications',
      component: () => import('@/views/notifications/EmailNotifications.vue'),
      meta: {
        pageTitle: 'Email Notifications',
        breadcrumb: [
          {
            text: 'Email Notifications',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/notification-types',
      name: 'notification-types',
      component: () => import('@/views/notifications/NotificationTypes.vue'),
      meta: {
        pageTitle: 'Notification Types',
        breadcrumb: [
          {
            text: 'Notification Types',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/topics-board',
      name: 'topics-board',
      component: () => import('@/views/topics/TopicLifecycles.vue'),
      meta: {
        pageTitle: 'Topics Review Board',
        breadcrumb: [
          {
            text: 'Topics Review Board',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/topics-review-statuses',
      name: 'topics-review-statuses',
      component: () => import('@/views/topics/TopicReviewStatuses.vue'),
      meta: {
        pageTitle: 'Topics Review Statuses',
        breadcrumb: [
          {
            text: 'Topics Review Statuses',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/ads-management',
      name: 'ads-management',
      component: () => import('@/views/adsManagement/AdsManagement.vue'),
      meta: {
        pageTitle: 'Ads Management',
        breadcrumb: [
          {
            text: 'Ads Management',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/ads-account',
      name: 'ads-account',
      component: () => import('@/views/adsAccount/AdsAccount.vue'),
      meta: {
        pageTitle: 'Ads Account',
        breadcrumb: [
          {
            text: 'Ads Account',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/gallery-tags',
      name: 'gallery-tags',
      component: () => import('@/views/galleryTags.vue'),
      meta: {
        pageTitle: 'Gallery Tags',
        breadcrumb: [
          {
            text: 'Gallery Tags',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/permission-users',
      name: 'permission-users',
      component: () => import('@/views/permission-users.vue'),
      meta: {
        pageTitle: 'Permissions',
        breadcrumb: [
          {
            text: 'Permissions',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/deleted-question',
      name: 'deleted-question',
      component: () => import('@/views/old_question.vue'),
      meta: {
        pageTitle: 'Deleted Questions',
        breadcrumb: [
          {
            text: 'Deleted Questions',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },

    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },

    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/categorylevels/:subcatId',
      name: 'apps-users-view',
      beforeEnter: guardMyroute,
      component: () => import('../views/categorylevels.vue'),
    },
    {
      path: '/account-setting',
      name: 'account-setting',
      component: () => import('../views/components/AccountSetting.vue'),
      meta: {
        pageTitle: 'Account Settings',
        breadcrumb: [
          {
            text: 'Account Settings',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/categorylevels/question/:levelId/:questionId?',
      name: 'apps-users-question',
      component: () => import('../views/question.vue'),
      beforeEnter: guardMyroute
    },
    {
      path: '/user-report/:AppuserId',
      name: 'app-users-report',
      component: () => import('../views/users-report.vue'),
      beforeEnter: guardMyroute
    },

    {
      path: '/class/subjects-report/:subjectId',
      name: 'class-subjects-report',
      component: () => import('../views/pages/addQuestionAgainstUsers.vue'),
      beforeEnter: guardMyroute
    },
    {
      path: '/class/subjects-report/categorys-report/:categoryId',
      name: 'class-subject-categorys-report',
      component: () => import('../views/pages/addQuestionAgainstUsersCategroy.vue'),
      beforeEnter: guardMyroute
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('../views/forgotPasswordScreen.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('../views/resetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/folder/:folderId/:name',
      name: 'folder',
      component: () => import('../views/pages/folder.vue'),
    },
    {
      path: '/billing/plans',
      name: 'billing-plans',
      component: () => import('@/views/billing/PlansList.vue'),
      meta: {
        pageTitle: 'Plans',
        breadcrumb: [
          {
            text: 'Plans',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },
    {
      path: '/question_logs',
      name: 'question_logs',
      props: true,
      component: () => import('@/views/question-logs.vue'),
      meta: {

        pageTitle: 'Question Logs',
        breadcrumb: [
          {
            text: 'Question Logs',
            active: true,
          },
        ],
      },
      beforeEnter: guardMyroute
    },

  ],
})
function guardMyroute(to, from, next) {
  if (localStorage.getItem("_t")) {
    var isAuthenticated = false;
    axios.get(process.env.VUE_APP_API_URL + "/tokencheck", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("_t")
      },
    }).then((get_response) => {
      if (get_response.data.message == 'authenticated') {
        isAuthenticated = true;
        var permissiondata = JSON.stringify(get_response.data.permission);

        localStorage.setItem("permission", permissiondata);
      } else {
        isAuthenticated = false;
      }
      if (isAuthenticated) {
        next();
      }
      else {
        window.localStorage.clear();
        next('/login');
      }
    }).catch(function (error) {
      if (error.response) {
        if (error.response.data.message == 'Unauthenticated.') {
          localStorage.removeItem("permission", { "permission": [] });
          window.localStorage.clear();
          next('./login')
        }

      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }

    });
  } else {
    next('/login');
  }
}

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
